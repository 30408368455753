import React, { useState } from 'react'
import { Col, FormControl, Row } from 'react-bootstrap'
import Swal from 'sweetalert2'

const SidebarMenu = () => {
  const [open, setOpen] = useState(false)

  let onSubmit = async e => {
    const data = {
      name: e.target[0].value,
      email: e.target[1].value,
      contact: e.target[2].value,
      company: e.target[3].value,
      message: e.target[4].value,
      title: 'Query from LCT360'
    }
    e.preventDefault()
    try {
      let res = await fetch(
        'https://api.gfuturetech.com/api/contact/sendMail',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      )
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Submitted',
          text: 'All the details are collected. We will contact you soon.'
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Some error occured. Please try again leter.'
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='sidebar-menu'>
      <div
        className={!open ? 'menu-bars' : 'menu-bars open'}
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
      </div>

      <div className={!open ? 'sidebar-wrap' : 'sidebar-wrap open'}>
        <div className='sidebar-menu-items'>
          <div className='lap-menu w-100'>
            {/* <a href="/news" className="sidebar-link">
              News & Insight
            </a> */}
            {/* <a href="/contact" className="sidebar-link">
              Contact
            </a> */}
            {/* <a href="/investor" className="sidebar-link">
              Investor
            </a> */}

            <form onSubmit={onSubmit}>
              <Row className='gy-4'>
                <Col xl='6'>
                  <FormControl placeholder='Full Name*' required />
                </Col>
                <Col xl='6'>
                  <FormControl placeholder='Email*' type='email' required />
                </Col>
                <Col xl='6'>
                  <FormControl
                    placeholder='Phone Number*'
                    type='number'
                    required
                  />
                </Col>
                <Col xl='6'>
                  <FormControl placeholder='Subject*' required />
                </Col>
                <Col xs='12'>
                  <FormControl
                    placeholder='Messages*'
                    as='textarea'
                    rows={5}
                    required
                  />
                </Col>

                <Col xs='12'>
                  <button type='submit' className='btn submit-btn'>
                    Submit
                  </button>
                </Col>
              </Row>
            </form>
          </div>

          <div className='mobile-menu'>
            <a href='/about-us' className='sidebar-link'>
              About Us
            </a>
            <a href='/esg' className='sidebar-link'>
              ESG
            </a>
            <a href='/team' className='sidebar-link'>
              Team
            </a>
            {/* <a href="/portfolio-partners" className="sidebar-link">
              Partners
            </a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidebarMenu
