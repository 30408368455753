import React from "react";
import { Col, Row } from "react-bootstrap";
import inside from '../../assets/img/inside.png'

const Brief = () => {
  return (
    <section className="brief-section">
      <Row className="g-0">
        <Col lg="6">
          <div className="brief-content-wrap">
            <h4 className="brief-text">
              REVOLUTIONIZING THE PROCESSING OF OIL AND GAS WHILE REDUCING GREEN HOUSE GASES (GHG's)
            </h4>
          </div>
        </Col>

        <Col lg="6">
          <div className="img-wrap d-flex align-items-center justify-content-center">
            <img src={inside} alt="" className="brief-img" />
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Brief;
