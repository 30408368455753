import React, { useEffect } from 'react'
import Loader from '../layout/loader'
import About from './About'
import Banner from './Banner'
import Brief from './Brief'
import Info from './Info'
import Plants from './Plants'
import Team from './Team'
import Aos from 'aos';
import Advantages from './Advantages'

const Home = () => {
  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <>
      <Loader />
      <Banner />
      <div className="main-body">
        <About
          title="OUR COMMITMENT TO YOU AND YOUR SUSTAINABILITY IN A CHANGING WORLD IS PARAMOUNT"
          desc="LCT360 technologies will help the petroleum industry transition to clean energy."
        />
        <div data-aos="fade-up" data-aos-duration="500">
          <Brief />
        </div>
        <About title="TRANSITIONING TO CLEAN ENERGY WILL OPTIMIZE THE OIL AND GAS INDUSTRY WHILE REDUCING EMISSIONS AT A GLOBAL SCALE" />
        <Team />
        <Plants />

        <Info />
        {/* <Advantages /> */}
      </div>
    </>
  );
}

export default Home
