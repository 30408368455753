import React, { useState } from "react";
import MenuItem from "./MenuItem";
import SidebarMenu from "./SidebarMenu";
import logo from '../../../assets/img/logo.png'
import MenuWithSub from './MenuWithSub'

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="header-wrap d-flex align-items-center justify-content-between">
      <div className="logo-wrap">
        <a href="/">
          <img src={logo} alt="" className="logo-img" height="50px" />
        </a>
      </div>

      <div className="menu-wrap d-flex align-items-center">
        {/* <div className={!open ? "menu-bars" : "menu-bars open"} onClick={() => {setOpen(!open)}}>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div> */}

        <div
          className={
            !open
              ? "desk-menu d-flex align-items-center"
              : "desk-menu open d-flex align-items-center"
          }
        >
          <MenuItem link="/about-us" name="About Us" />

          <MenuWithSub link="/technology" name="Technology">
            <MenuItem
              link="/technology/flare-capture-and-monetization"
              name="Flare Recovery Technology"
            />
            <MenuItem
              link="/technology/natural-gas-liquids-recovery"
              name="Natural Gas-Liquid Technology"
            />
            <MenuItem
              link="/technology/increased-pipeline-capacity"
              name="Increased Pipeline Capacity"
            />
          </MenuWithSub>

          <MenuItem link="/esg" name="ESG" />

          <MenuItem link="/team" name="Team" />

          {/* <MenuItem link="/portfolio-partners" name="Partners" /> */}
        </div>

        <SidebarMenu />
      </div>
    </div>
  );
};

export default Header;
