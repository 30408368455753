import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import logo from '../../../assets/img/logo-icon.png'

const Footer = () => {
  return (
    <div className='footer-wrap'>
      <div className='footer-top d-md-flex align-items-center'>
        <a href='/' className='footer-logo'>
          <img src={logo} alt='' />
          {/* <p className="footer text-white fs-4">LCT360</p> */}
        </a>

        <div className='footer-menu d-md-flex align-items-center'>
          <a href='/' className='footer-link'>
            Home
          </a>
          <a href='/about-us' className='footer-link'>
            About Us
          </a>
          <a href='/team' className='footer-link'>
            Teams
          </a>
          <a href='/esg' className='footer-link'>
            ESG
          </a>
          <a href='/portfolio-partners' className='footer-link'>
            Portfolio Partners
          </a>
        </div>
      </div>

      <div className='footer-bottom'>
        <div className='d-md-flex align-items-center justify-content-between'>
          <p className='copyright'>© 2023 LCT360. All Rights Reserved.</p>

          <div className='social-wrap d-flex align-items-center'>
            <a
              href='https://www.linkedin.com/company/lct-360'
              className='social-link'
            >
              {' '}
              <FontAwesomeIcon icon={faLinkedinIn} />{' '}
            </a>
            <a href='tel:2816059553' className='social-link'>
              {' '}
              <FontAwesomeIcon icon={faPhoneFlip} />{' '}
            </a>
            <a href='mailto:info@lct360.com' className='social-link'>
              {' '}
              <FontAwesomeIcon icon={faEnvelope} />{' '}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
