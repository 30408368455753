import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import InnerBanner from '../innerBanner'
import PageHeading from '../pageHeading'
import Team from '../team'
import jay from '../../assets/img/team/jayy.png'
import andy from '../../assets/img/team/manish.png'
import inside from '../../assets/img/inside.png'
// import Bottom from "../home/Bottom";
import Loader from '../layout/loader'
import Aos from 'aos'

const AboutUs = () => {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <>
      <Loader />
      <InnerBanner title='About Us' />
      <PageHeading heading='LCT360 brings revolutionary technology that changes all oil and gas production across the world. At the same time its global impact on Green House Gas (GHG) reductions will aid the industry transition towards a COP26 Carbon Neutral Future.' />

      <div className='about-owner'>
        <div className='about-heading-wrap'>
          <div className='about-heading'>
            <div data-aos-duration='500' data-aos='fade-up'>
              <p className='text-center'>Expertise and Integrity - Our CEO</p>
            </div>
          </div>
        </div>

        <div className='owner-detail'>
          <Row className='align-items-md-end justify-content-between gy-4'>
            <Col lg='4'>
              <div className='owner-img-wrap'>
                <div data-aos-duration='700' data-aos='fade-right'>
                  <Team img={jay} name='Dr. Jay Patel' position='CEO' />
                </div>
              </div>
            </Col>
            <Col lg='8'>
              <div data-aos-duration='700' data-aos='fade-up'>
                <p className='about-section-title'>Dr. Jay patel</p>
                <p className='about-section-desc'>
                  Jay Patel, Doctor of Medicine and a Philanthropist has
                  supported many initiatives across various institutions and
                  geographies. Being visionary evangelist, Dr. Jay set foot into
                  entrepreneurship and so founded multiple companies to help
                  businesses reach their true potential. He is the founder and
                  CEO of EDGE196. He strongly believes that when one startup
                  becomes a unicorn, it can inspire and motivate a new
                  generation of entrepreneurs. Dr. Jay's commercial and business
                  acumen provides invaluable leadership to all his projects.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className='mission-wrap'>
          <div className='mission-content'>
            <div data-aos-duration='700' data-aos='fade-up'>
              <p className='mission'>
                Our mission is to use our proprietary technology for improve
                global energy security, reduce carbon emission, increase
                productivity and promote ESG driven initiatives.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='about-inner-section'>
        <div data-aos-duration='700' data-aos='fade-up'>
          <h4 className='inner-about-title'>
            OUR COMMITMENT TO YOU AND YOUR SUSTAINABILITY IN A CHANGING WORLD IS
            PARAMOUNT
          </h4>
        </div>

        <div data-aos-duration='700' data-aos='fade-up'>
          <div className='about-info-wrap'>
            <div className='earth-img'>
              <img src={inside} alt='' />
            </div>

            <div className='about-info-content'>
              <p className='highlight-text'>
                LCT360 POWER PLANTS EMIT ABOUT 99 PERCENT LESS CARBON DIOXIDE
                THAN FOSSIL FUEL POWER PLANTS OF SIMILAR SIZE.
              </p>

              <p className='desc-text'>
                REDUCING EMISSIONS AT A GLOBAL SCALE WILL HELP THE WORLD GET TO
                CARBON-NEUTRAL AND CARBON NET-ZERO WELL AHEAD OF TARGET DATE
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <Bottom /> */}
    </>
  )
}

export default AboutUs
