import React from 'react'
import info from '../../assets/img/info-shape.png'

const Info = () => {
  return (
    <section className="info-section">
      <img src={info} alt="" className="shape" />

      <div className="info-content d-flex justify-content-end">
        <div
          data-aos-duration="500"
          data-aos="fade-right"
        >
          <p className="info-text">
            “REDUCING EMISSIONS AT A GLOBAL SCALE WILL HELP THE WORLD GET TO
            CARBON-NEUTRAL AND CARBON NET-ZERO WELL AHEAD OF TARGET DATE.”
          </p>
        </div>
      </div>
    </section>
  );
}

export default Info
