import React from "react";
import video from "../../assets/video/Geothermalcore.mp4";
import banner from "../../assets/img/banner.png";
import logo from "../../assets/img/logo-lg.png";

const Banner = () => {
  return (
    <div className="banner-wrap">
      <div data-aos-duration="500" data-aos="fade-up" data-aos-delay="1100">
        <img src={banner} className="banner-video" />
      </div>
      <div className="overlay"></div>

      <div className="banner-content">
        <div
          data-aos-duration="500"
          data-aos="fade-up"
          data-aos-delay="1300"
          className="d-flex flex-column align-items-center"
        >
          <h3 className="banner-title text-center">
            PROVIDING ENVIRONMENTALLY AND FINANCIALLY RESPONSIBLE OIL & GAS LOW
            CARBON TECHNOLOGIES
          </h3>
          <p className="banner-content-text text-white text-center">
            LCT360 brings revolutionary technology that changes all oil and gas
            production across the world. At the same time its global impact on
            Green House Gas (GHG) reductions will aid the industry transition
            towards a COP27 Carbon Neutral Future.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
