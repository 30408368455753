import React from 'react'
import InnerBanner from '../innerBanner'
import PageHeading from '../pageHeading'
import About from '../home/About'
import Loader from '../layout/loader'
import PortfolioBox from './PortfolioBox'
import save from '../../assets/img/save.png'
import geo from '../../assets/img/geo.png'
import trans from '../../assets/img/trans.png'
import lct from '../../assets/img/logo_c.png'

const Contact = () => {
  return (
    <>
      <Loader />
      <InnerBanner title='PORTFOLIO PARTNERS' />
      <div className='portfolio-partners'>
        <PageHeading heading='Our portfolio is an extension of our firm, and our management teams are our long-term partners – many across multiple investments.' />
        <div className='padding-x pt-5'>
          <p className='text mb-3'>
            LCT360 has worked to drive the development of each of the companies
            in our portfolio through due diligence, patent filing, business
            development and other support. While each of these companies is at a
            different stage of development, they have all progressed through our
            partnership.
          </p>

          <ul>
            <li className='mb-4'>
              <b>Geothermal - </b>Organic Rankin Cycle Geothermal plants in
              previously underdeveloped markets
            </li>
            <li className='mb-4'>
              <b>REHM - </b>Resonance Emission Methane Hydrate Extraction
            </li>
            <li className='mb-4'>
              <b>LCT - </b>Low Carbon Technology - Flaring Reduction and
              pipeline efficiency to reduce methane escape
            </li>
            <li className='mb-4'>
              <b>Savy Electric Vehicles - </b>Building high quality, affordable
              electric vehicles for a global population
            </li>
          </ul>
        </div>
        <About title='Active Investments' />
        <div className='portfolio-wrap'>
          <PortfolioBox link='https://savyelectric.in/' img={save} />
          <PortfolioBox link='https://www.lct360.com' img={lct} />
          <PortfolioBox link='https://www.LCT360.com/' img={trans} />
          <PortfolioBox link='https://www.geothermalcore.com/' img={geo} />
        </div>
      </div>
    </>
  )
}

export default Contact
