import React from 'react'
import { Col, Row } from 'react-bootstrap'
import InnerBanner from '../innerBanner'
import PageHeading from '../pageHeading'
import PrimaryBgHeading from '../primaryBgHeading'
import TechnologyBox from '../technologyBox'
import t1 from '../../assets/img/rehmtechnology.png'
import t2 from '../../assets/img/arcs1.png'
import t3 from '../../assets/img/swers1.png'
import Loader from '../layout/loader'

const Technology = () => {
  return (
    <>
      <Loader />
      <InnerBanner title="TECHNOLOGY" />
      <PageHeading
        heading="TRANSITIONING TO CLEAN ENERGY WILL OPTIMIZE THE OIL AND GAS INDUSTRY WHILE REDUCING EMISSIONS AT A GLOBAL SCALE"
        align="text-start"
      />

      <div className="small-info">
        <Row>
          <Col lg="3" className="d-none d-lg-block"></Col>
          <Col lg="9">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="small-info-text">
                The introduction of LCT360 technologies into oil and gas
                production will optimize the entire economics of the
                petrochemical sector.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <PrimaryBgHeading heading="TECHNOLOGY WE FOCUS IN" />

      <div className="techno-box-wrap">
        <Row className="gy-4">
          <Col md="6" xl="4">
            <div data-aos-duration="700" data-aos="fade-right">
              <TechnologyBox
                img={t1}
                show="d-block"
                link="/technology/flare-capture-and-monetization"
                title="FLARE RECOVERY TECHNOLOGY"
              />
            </div>
          </Col>

          <Col md="6" xl="4">
            <div
              data-aos-duration="700"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <TechnologyBox
                img={t2}
                show="d-block"
                link="/technology/natural-gas-liquids-recovery"
                title="NATURAL GAS LIQUIDS RECOVERY"
              />
            </div>
          </Col>

          <Col md="6" xl="4">
            <div
              data-aos-duration="700"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <TechnologyBox
                img={t3}
                show="d-block"
                link="/technology/increased-pipeline-capacity"
                title="INCREASED PIPELINE CAPACITY"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Technology