import React from "react";
import InnerBanner from "../innerBanner";
import PageHeading from "../pageHeading";
import img from "../../assets/img/flashplant.png";
import rhem1 from "../../assets/img/rehm1.png";
import { Col, Row } from "react-bootstrap";
import DetailBottomBanner from "./DetailBottomBanner";
import TechnologyBox from "../technologyBox";
import Loader from "../layout/loader";
import PrimaryBgHeading from "../primaryBgHeading";

const FlareRecoveryTechnology = () => {
  return (
    <>
      <Loader />
      <InnerBanner title="Our TECHNOLOGY" />
      <PageHeading heading="FLARE RECOVERY TECHNOLOGY" align="text-start" />

      <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
          <Col xxl="6">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                The industry currently flares hydrocarbons into the atmosphere
                commonly where there is no nearby pipeline infrastructure, and
                otherwise during routine maintenance or safety driven flaring
                events. Flaring is igniting and burning of valuable excess
                hydrocarbon gases into the atmosphere across the industry, both
                onshore and offshore. These flared hydrocarbons release GHG’s
                such as carbon dioxide and methane that create large carbon
                footprints as well as increases global warming.
              </p>
            </div>
          </Col>

          <Col xxl="5">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
              className="overflow-hidden"
            >
              <img
                src={rhem1}
                alt="dry steam plant"
                className="plant-info-img"
              />
            </div>
          </Col>

          <Col xs="12">
            <div data-aos-duration="700" data-aos="fade-up">
              <p className="detail-info-text">
                The United States ranks second in the flaring of natural gas
                world-wide and thus a high GHG emitter and is responsible for a
                substantial loss of revenue in the oil and gas sector. Texas and
                North Dakota alone account for 85% of the nation’s natural gas
                losses from flaring. It is forecasted that in the year 2022,
                22,600 new wells will be drilled across the United States.
                According to a recent 2020 study, in the state of Texas, 6,589
                wells were considered new drills, re-entries, and
                re-completions. With early well completions, these permitted oil
                productions can be boosted by eliminating much of the flaring
                using LCT360’s technology. This technology is also scalable to
                handle the substantially large gas flaring of major oil
                refineries around the globe.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <PrimaryBgHeading heading="ASSISTING CLIMATE CONTROL" />
      <PageHeading
        heading="The World Bank attributes the rise in global temperature partly to the 492 billion cubic feet of natural gas that is flared at oil and gas wellheads around the world annually resulting in more than 350 million tons of Carbon Dioxide emissions (CO2e)."
        align="text-start"
      />

      <div className="detail-info-wrap">
        <Row className="gy-5 justify-content-between">
          <Col xxl="5">
            <div data-aos-duration="700" data-aos="fade-up">
              <img src={img} alt="dry steam plant" className="plant-info-img" />
            </div>
          </Col>

          <Col xxl="6">
            <div
              data-aos-duration="700"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <p className="detail-info-text">
                LCT360’s 99% flare recovery and natural gas stabilization
                technology can solve the increase in global warming,
                dramatically reduce GHG emissions, and reduce the world’s carbon
                footprint well ahead of the years 2050 target date. LCT360 can
                also reduce the World Bank’s Global Gas Flaring Reductions
                Partnership and Zero Routing Flaring by 2030 initiative to a
                much sooner year. Simply put, the installation of LCT360’s
                technology into the flare system at both oil and gas upstream
                and downstream facilities such as oil refineries, onshore well
                heads, and offshore platforms will create a greener earth for
                humanity.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <DetailBottomBanner
        techBox1={
          <TechnologyBox
            link="/technology/natural-gas-liquids-recovery"
            title="NATURAL GAS LIQUIDS RECOVERY"
          />
        }
        techBox2={
          <TechnologyBox
            link="/technology/increased-pipeline-capacity"
            title="INCREASED PIPELINE CAPACITY"
          />
        }
      />
    </>
  );
};

export default FlareRecoveryTechnology;
