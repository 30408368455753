const Team = [
  {
    id: 2,
    name: "Kirti Patel",
    position: "Founder & CTO",
    img_path: require("../../assets/img/team/Kriti.png"),
    category: "capital",
    linkedIn: "https://www.linkedin.com/in/kirti-patel-220238177/",
  },
  {
    id: 1,
    name: "Dr. Jay Patel",
    position: "Chairman & CEO",
    img_path: require("../../assets/img/team/jayy.png"),
    category: "capital",
    linkedIn: "https://www.linkedin.com/in/jay-patel-77795910b/",
  },
  // {
  //   id: 8,
  //   name: "Frank Monachelli",
  //   position: "Chief Financial Officer",
  //   img_path: require("../../assets/img/team/Frank.png"),
  //   category: "innovation",
  //   linkedIn: "https://www.linkedin.com/in/frankmonachelli/",
  // },
  // {
  //   id: 4,
  //   name: "Darrell Gordon",
  //   position: "Head of Accounting",
  //   img_path: require("../../assets/img/team/Darrell.png"),
  //   category: "royalties",
  //   linkedIn: "https://www.linkedin.com/in/darrell-gordon-204871a8/",
  // },
  // {
  //   id: 10,
  //   name: "Mohan Anem",
  //   position: "International Financial Advisor",
  //   img_path: require("../../assets/img/team/Mohan.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/mohananem/",
  // },
  // {
  //   id: 13,
  //   name: "Dr. Andrew Davidoff",
  //   position: "Advisor",
  //   img_path: require("../../assets/img/team/Andrew.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/andrew-joseph-davidoff/",
  // },
  // {
  //   id: 9,
  //   name: "Trey Storer",
  //   position: "Head of Business Development",
  //   img_path: require("../../assets/img/team/treyy.png"),
  //   category: "e&p",
  //   linkedIn: "https://www.linkedin.com/in/trey-storer/",
  // },
  {
    id: 11,
    name: "Manish Mishra",
    position: "International Statergy",
    img_path: require("../../assets/img/team/manish.png"),
    category: "e&p",
    linkedIn: "https://www.linkedin.com/in/manishmishraenergyallied/",
  },
  {
    id: 7,
    name: "Mayur Patel",
    position: "Head Of Operation",
    img_path: require("../../assets/img/team/mayur.png"),
    category: "innovation",
    linkedIn: "https://www.linkedin.com/in/mayur-patel-273746235/",
  },

  {
    id: 3,
    name: "CK Patel",
    position: "Project Manager",
    img_path: require("../../assets/img/team/CK.png"),
    category: "capital",
    linkedIn: "https://www.linkedin.com/in/ckpatel1974/",
  },
  // {
  //   id: 5,
  //   name: "Rajesh Desai",
  //   position: "EPC Advisor",
  //   img_path: require("../../assets/img/team/Rajesh.png"),
  //   category: "royalties",
  //   linkedIn: "https://www.linkedin.com/in/raj-desai-56728a5/",
  // },
  {
    id: 6,
    name: "Anil Ali",
    position: "Legal Counsel",
    img_path: require("../../assets/img/team/Anel.png"),
    category: "royalties",
    linkedIn: "https://www.linkedin.com/in/anil-ali-a076364/",
  },
];

export default Team;
