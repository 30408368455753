import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/sass/index.scss';
import { About, IncreasedPipelineCapacity, FlareRecoveryTechnology, NaturalGasLiquidTechnology, Home, TeamPage, Technology, Contact, PortfolioPartner, ESG, Advantages, Investor, News, LeadingProgram } from './components';
import Layout from './components/layout';
import Aos from 'aos';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="technology" element={<Technology />} />
          <Route
            path="technology/flare-capture-and-monetization"
            element={<FlareRecoveryTechnology />}
          />
          <Route
            path="technology/natural-gas-liquids-recovery"
            element={<NaturalGasLiquidTechnology />}
          />
          <Route
            path="technology/increased-pipeline-capacity"
            element={<IncreasedPipelineCapacity />}
          />
          <Route path="team" element={<TeamPage />} />
          <Route path="contact" element={<Contact />} />
          <Route path="portfolio-partners" element={<PortfolioPartner />} />
          <Route path="esg" element={<ESG />} />
          <Route path="advantages" element={<Advantages />} />
          <Route path="investor" element={<Investor />} />
          <Route path="news" element={<News />} />
          <Route path="leading-program" element={<LeadingProgram />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
